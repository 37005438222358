.fileRecordBlock {
  margin: 10px;
  border: solid 2px white;
  width: 800px;
  border-radius: 10px;
}

.downloadIcon {
  float: right;
  margin-right: 20px;
  margin-top: 2px;
}

.processingStatusIcon {
  float: right;
  margin-right: 20px;
  margin-top: 2px;
}

.closeButton {
  float: right;
  margin-right: 20px;
  margin-top: 2px;

  &:hover {
    color: gray;
  }
}
