/*Those 3 lines required to work with tailwind*/
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    height: 100%;
}

.App {
    height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    flex-grow: 1;
}
